.profile-img{
    max-width: 200px;
    border-radius: 96px;
}

pre.highlight{
    padding: 10px;
}

table {
  width: 100%;
  display: block;
  overflow: auto;
  margin-bottom: 16px;
}

table td, table th {
    padding: 6px 13px;
    border: 1px solid #dfe2e5;
}

table tr {
  background-color: #fff;
  border-top: 1px solid #c6cbd1;
}